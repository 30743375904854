import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Trasnmigration of Soul",
  "date": "2020-08-20T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Generally we say, our body is just a vehicle in which the Soul travels across the universe. Souls travels from different types of vehicles through various unmanifested/manifested bodies. Darvin's theory underline the anthropogenic—selection based evolution which is the main force in bacterial evolution. The eraliest life forms we know of were microbes (microscopic organism) which is about 3.7 billion years old, consisting the very similar Carbon molecule we inhibit as of now. The first ever living organism on Earth was  known as cyanobacteria, which is indeed very small in size and it ain't got any definte shape or structure. All it was having is a 'Life Force'or  'Prana' or 'The Divine spirit' or 'The Soul'.`}</p>
    <p>{`According to Taittiriya Upanishad our Soul tends to transform and travel from one form to another in a perpetual cyclic manner. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      